/*Browsers which partially support CSS Environment variables (iOS 11.0-11.2).*/
@supports (padding-top: constant(safe-area-inset-top)) {
    :root {
        --safe-inset-top: constant(safe-area-inset-top);
        --safe-inset-left: constant(safe-area-inset-left);
        --safe-inset-right: constant(safe-area-inset-right);
        --safe-inset-bottom: constant(safe-area-inset-bottom);
    }
}

/*Browsers which fully support CSS Environment variables (iOS 11.2+).*/
@supports (padding-top: env(safe-area-inset-top)) {
    :root {
        --safe-inset-top: env(safe-area-inset-top);
        --safe-inset-left: env(safe-area-inset-left);
        --safe-inset-right: env(safe-area-inset-right);
        --safe-inset-bottom: env(safe-area-inset-bottom);
    }
}

@supports (padding: max(0px)) {
    :root {
        --safe-inset-top: max(40px, constant(safe-area-inset-top));
        --safe-inset-top: max(40px, env(safe-area-inset-top));
    }
}

html {
    scroll-behavior: smooth;
    background: #8DC442;
}

body {
    -webkit-touch-callout: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -webkit-text-size-adjust: none;

    font-family: "Open Sans", Helvetica, sans-serif;
}

a {
    color: #066834;
}

#app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#app.app--cordova #app__header {
    padding-top: var(--safe-inset-top);
}

#app.is-landscape #app__header {
    padding-top: 0;
    padding-left: var(--safe-inset-left);
    padding-right: var(--safe-inset-right);
}

#app__page {
    flex: 1 1 100%;
    margin-top: 64px; /* baseline */
}

@supports (padding: min(0px)) {
    #app__page {
        margin-top: calc(10px + var(--safe-inset-top));
        /*margin-top: min(64px, var(--safe-inset-top));*/
    }
}

@media screen and (min-width: 960px) {
    #app__page {
        margin-top: 0;
    }
}

#app.app--cordova #app__page {
    padding-top: var(--safe-inset-top);
}

#app.is-landscape #app__page {
    /*padding-top: 0;*/
    padding-left: var(--safe-inset-left);
    padding-right: var(--safe-inset-right);
    /*padding-left: max(env(safe-area-inset-left), env(safe-area-inset-top));*/
}

#app__footer {
    padding-top: 2rem;
}

#app #app__footer {
    padding-bottom: var(--safe-inset-bottom);
}

#app.is-landscape #app__footer > div {
    padding-left: var(--safe-inset-left);
    padding-right: var(--safe-inset-right);
}

@media print {


}

/* avoid blue outline on MUI modals on cordova/native */
.mui-fixed [role="document"][tabindex="-1"]:focus {
    outline: none
}
